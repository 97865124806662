import React, { Component } from 'react';
import { Col, Row, Container } from 'react-bootstrap';
import UserService from '../../services/UserService';
import { Link } from 'react-router-dom';


class courseplan extends Component {

    constructor(props) {
        super(props);

        this.state = {
            INQGuId: '',
            KGMGuId: '',
            INQParentName: '',
            INQParentEmail: '',
            INQParentMobile: '',
            INQPassword: '',
            INQKidsName: '',
            INQIsLaptop: false,
            INQAlternetNumber: '',
            INQAlternetEmail: '',
            INQCreateDate: '',
            INQStatus: '',
            INQIsDemo: '',
            isSubmitting: false,
            E: '',
            data: [],
            nameerr: '',
            emailerrer: '',
            mobileerroe: '',
            chnames: '',
            loading1: true,
            loading2: true,
            year: '',
            Plan: [],
        };



        fetch(UserService.API_URL1 + 'UserKidsGroup/List', {
            method: 'GET',
            headers: {
                //Authorization: 'Bearer ' + localStorage.getItem('tokens'),
                'Content-Type': 'application/json',
                'Access-Control-Allow-Origin': '*'

            }
        })
            .then((response) => response.json())
            .then((response) => {
                //   console.log(response);
                this.setState({ data: response, loading1: false });


                this.state.data.map((item, i) =>
                    i == 0 ? (
                        fetch(UserService.API_URL1 + 'UserKidsGroupPlan/GetPlan/' + item.kgmGuId, {
                            method: 'GET',
                            headers: {

                                'Content-Type': 'application/json',
                                'Access-Control-Allow-Origin': '*'

                            }
                        })
                            .then((response) => response.json())
                            .then((response) => {
                                this.setState({ KGMGuId: item.kgmGuId })
                                console.log("GetPlan", response);
                                this.setState({ Plan: response, loading: false, loading2: false });

                            },
                                err => {
                                    console.log(err);

                                })
                    ) : null


                )



            })


        this.onChangeValue = this.onChangeValue.bind(this);


    }



    onChangeValue(event) {
        console.log(event);
        this.setState({ KGMGuId: event, loading2: true })

        fetch(UserService.API_URL1 + 'UserKidsGroupPlan/GetPlan/' + event, {
            method: 'GET',
            headers: {
                //Authorization: 'Bearer ' + localStorage.getItem('tokens'),
                'Content-Type': 'application/json',
                'Access-Control-Allow-Origin': '*'

            }
        })
            .then((response) => response.json())
            .then((response) => {
                //  console.log("GetPlan", response);
                this.setState({ Plan: response, loading: false, loading2: false });
            },
                err => {
                    console.log(err);
                    // this.props.history.push('/Login');
                });
    }


    checkout(id, old, newp, clas, title) {

        localStorage.setItem('kdipd', id);
        localStorage.setItem('kgmid', this.state.KGMGuId);
        localStorage.setItem('old', old);
        localStorage.setItem('newp', newp);
        localStorage.setItem('cla', clas);
        localStorage.setItem('title', title);

        window.location.href = '/registers'
    }


    getYear() {
        return new Date().getFullYear();
    }


    render() {
        return (

            <div className="py-3 text-center bg-light">

                <Container className="course_plan mt-3">

                    <div className="d-md-flex justify-content-between align-items-center mb-2">
                        <Link to="/course-plan">
                            <img
                                src="/favi.png"
                                className="mx-auto d-md-block d-none mb-3"
                                width="60"
                            />
                        </Link>
                        <div class="text-secondary pb-1 p-md-0" style={{ fontSize: 20, fontSize: 30 }}>
                            <span className="text-theme2">PRELAUNCH</span>
                            <span class="text-theme" style={{ fontWeight: 600 }}>
                                {" "}
                        OFFER
                    </span>
                        </div>
                        <Link to="/">
                            <img
                                src="/Images/logo.jpg"
                                className="mx-auto d-block mb-3"
                                width="150"
                            />
                        </Link>
                    </div>




                    <Row>
                        <Col md={12}>
                            {
                                this.state.loading1 === true ? (
                                    <span>Loading...</span>
                                ) : (
                                        <div>
                                            <div className="nav nav-pills flex-row justify-content-center left_menu mb-md-3 mb-3" role="tablist" aria-orientation="vertical">
                                                {this.state.data.map(item =>
                                                    <div id={item.kgmGuId}>
                                                        {
                                                            this.state.KGMGuId === item.kgmGuId ?
                                                                (
                                                                    <div>
                                                                        <a className="nav-link active" id={item.kgmGuId} data-toggle="pill" href={"#" + item.kgmGuId} role="tab" onClick={() => this.onChangeValue(item.kgmGuId)} aria-controls="v-pills-home" aria-selected="true" >
                                                                            <h6 className="font-weight-bold mb-0">{item.kgmTitle}</h6>
                                                                            <span className="small"> Class ({item.kgmClassTitle})</span>
                                                                        </a>
                                                                    </div>
                                                                )
                                                                :
                                                                (
                                                                    <div>
                                                                        <a className="nav-link" id={item.kgmGuId} data-toggle="pill" href={"#" + item.kgmGuId} role="tab" onClick={() => this.onChangeValue(item.kgmGuId)} aria-controls="v-pills-home" aria-selected="" >
                                                                            <h6 className="font-weight-bold mb-0">{item.kgmTitle}</h6>
                                                                            <span className="small"> Class ({item.kgmClassTitle})</span>
                                                                        </a>
                                                                    </div>
                                                                )
                                                        }
                                                    </div>

                                                )}
                                            </div>
                                        </div>
                                    )
                            }
                        </Col>
                    </Row>
                    <Row>

                        {
                            this.state.loading2 === true ? (
                                <span>Loading...</span>
                            ) : (

                                    this.state.Plan.map(item =>

                                        <Col md={4} sm={6}>
                                            <div className="pricingTable">

                                                <div>

                                                    <div className="pricingTable-header">
                                                        <h3 className="title">{item.kdpTitle}</h3>
                                                        <p>{item.kdpClass}</p>
                                                    </div>
                                                    <div className="pricing-content">

                                                        <h6>Curriculum</h6>

                                                        <p> {item.kdpDescription}</p>


                                                        {/* <h6>Achievements</h6>
                                                        <div className="d-flex py-2 justify-content-between text-center">
                                                            <div className="p-2 rounded border" style={{ flexBasis: '32%' }}>
                                                                <img src="/Images/icon1.png" className="img-fluid mb-1" style={{ width: 40 }} />
                                                                <p className="mb-0">introduction to coding</p>
                                                            </div>
                                                        </div> */}
                                                    </div>

                                                    <h6 className="view_pdf position-relative"><span />
                                                        <a className="d-block w-100 p-1 text-white position-relative" style={{ textDecoration: 'none', zIndex: 99 }} href={'/courses/' + item.kgmTitle.toLowerCase()} target="_blank">View Course Details</a><span className="new" /></h6>


                                                    {/* <div className="text-left d-flex justify-content-between align-items-center">
                                                        <a className="p-1 position-relative small text-info" style={{ textDecoration: 'underline', fontWeight: '700', fontSize: 10 }} href={'/courses/' + item.kgmTitle.toLowerCase()} target="_blank">View Course Details</a>
                                                    </div>
 */}

                                                    <div className="price-value">


                                                        <div className="price-value">

                                                            <span className="price-title">
                                                                Actual Price <b><del><i class="fas fa-rupee-sign small text-dark"></i>&nbsp;{item.kdpPrice}</del></b>
                                                            </span>

                                                            <span className="price-amount">
                                                                {" "}
                                                                <span className="d-block"><i class="fas fa-rupee-sign small text-dark"></i>&nbsp;{item.kdpOfferPrice}</span>
                                                                <small className="duration badge badge-pill pb-1" style={{ fontSize: 10, fontWeight: '800' }}>{item.kdpBaseTitle}</small>
                                                            </span>

                                                        </div>


                                                    </div>
                                                    <div className="pricingTable-signup">
                                                        <button onClick={() => this.checkout(item.kdpGuId, item.kdpPrice, item.kdpOfferPrice, item.kdpClass, item.kgmTitle)} >Book Now</button>
                                                    </div>
                                                </div>

                                            </div>

                                        </Col>

                                    )


                                )
                        }

                        <Col md={12} className="text-center p-2">

                            <h6 className="small text-center" style={{ fontWeight: '600' }}>&copy;  Copyright {this.getYear()}, IANTKids. </h6>

                        </Col>

                        {/* <Col md={4} sm={6}>
                            <div className="pricingTable">
                                <div className="pricingTable-header">
                                    <h3 className="title">App Developer Certification</h3>
                                    <p><b>48</b> Classes</p>
                                </div>
                                <div className="pricing-content">
                                    <h6>Carriculum</h6>
                                    <p>Sequences, Algorithms, Variables, Functions, Events</p>
                                    <h6>Activities</h6>
                                    <p>Create your own games and basic apps</p>
                                    <h6>Cognitive Benifits</h6>
                                    <p>Improvement in logical thinking</p>
                                    <h6>Achievements</h6>
                                    <div className="d-flex py-2 justify-content-between text-center">
                                        <div className="p-2 rounded border" style={{ flexBasis: '32%' }}>
                                            <img src="/Images/icon2.png" className="img-fluid mb-1" style={{ width: 40 }} />
                                            <p className="mb-0">Certificate</p>
                                        </div>
                                        <div className="p-2 rounded border" style={{ flexBasis: '32%' }}>
                                            <img src="/Images/icon3.png" className="img-fluid mb-1" style={{ width: 40 }} />
                                            <p className="mb-0">interactive Game Design</p>
                                        </div>
                                        <div className="p-2 rounded border" style={{ flexBasis: '32%' }}>
                                            <img src="/Images/icon4.png" className="img-fluid mb-1" style={{ width: 40 }} />
                                            <p className="mb-0">Playstore App</p>
                                        </div>
                                    </div>
                                </div>


                                <div className="price-value">
                                    <span className="price-title">
                                        Old Price <b><del><i class="fas fa-rupee-sign small text-dark"></i>&nbsp;27899</del></b>
                                    </span>
                                    <span className="price-amount">
                                        {" "}
                                        <i class="fas fa-rupee-sign small text-dark"></i>&nbsp;26249
                     <span className="duration badge badge-pill">save 4%</span>
                                    </span>
                                </div>
                                <div className="pricingTable-signup">
                                    <a href="#">Book Now</a>
                                </div>
                            </div>
                        </Col>

                        <Col md={4} sm={6}>
                            <div className="pricingTable">
                                <div className="pricingTable-header">
                                    <h3 className="title">App Developer Certification</h3>
                                    <p><b>48</b> Classes <span className="float-right badge badge-pill badge-warning mt-2">most popular</span></p>
                                </div>
                                <div className="pricing-content">
                                    <h6>Carriculum</h6>
                                    <p>Sequences, Algorithms, Variables, Functions, Events</p>
                                    <h6>Activities</h6>
                                    <p>Create your own games and basic apps</p>
                                    <h6>Cognitive Benifits</h6>
                                    <p>Improvement in logical thinking</p>
                                    <h6>Achievements</h6>
                                    <div className="d-flex py-2 justify-content-between text-center">
                                        <div className="p-2 rounded border" style={{ flexBasis: '32%' }}>
                                            <img src="/Images/icon2.png" className="img-fluid mb-1" style={{ width: 40 }} />
                                            <p className="mb-0">Certificate</p>
                                        </div>
                                        <div className="p-2 rounded border" style={{ flexBasis: '32%' }}>
                                            <img src="/Images/icon3.png" className="img-fluid mb-1" style={{ width: 40 }} />
                                            <p className="mb-0">interactive Game Design</p>
                                        </div>
                                        <div className="p-2 rounded border" style={{ flexBasis: '32%' }}>
                                            <img src="/Images/icon4.png" className="img-fluid mb-1" style={{ width: 40 }} />
                                            <p className="mb-0">Playstore App</p>
                                        </div>
                                    </div>
                                </div>


                                <div className="price-value">
                                    <span className="price-title">
                                        Old Price <b><del><i class="fas fa-rupee-sign small text-dark"></i>&nbsp;35999</del></b>
                                    </span>
                                    <span className="price-amount">
                                        {" "}
                                        <i class="fas fa-rupee-sign small text-dark"></i>&nbsp;33999
                     <span className="duration badge badge-pill">save 4%</span>
                                    </span>
                                </div>
                                <div className="pricingTable-signup">
                                    <a href="#">Book Now</a>
                                </div>
                            </div>
                        </Col>

                        <Col md={4} sm={6}>
                            <div className="pricingTable">
                                <div className="pricingTable-header">
                                    <h3 className="title">Advance Coding with Space-Tech</h3>
                                    <p><b>144</b> Classes <span className="float-right badge badge-pill badge-warning mt-2">best value</span></p>
                                </div>
                                <div className="pricing-content">
                                    <h6>Carriculum</h6>
                                    <p>Sequences, Algorithms, Variables, Functions, Events</p>
                                    <h6>Activities</h6>
                                    <p>Create your own games and basic apps</p>
                                    <h6>Cognitive Benifits</h6>
                                    <p>Improvement in logical thinking</p>
                                    <h6>Achievements</h6>
                                    <div className="d-flex py-2 justify-content-between text-center">
                                        <div className="p-2 rounded border" style={{ flexBasis: '32%' }}>
                                            <img src="/Images/icon5.png" className="img-fluid mb-1" style={{ width: 40 }} />
                                            <p className="mb-0">Lifetime Access</p>
                                        </div>
                                        <div className="p-2 rounded border" style={{ flexBasis: '32%' }}>
                                            <img src="/Images/icon6.png" className="img-fluid mb-1" style={{ width: 40 }} />
                                            <p className="mb-0">Space Tech</p>
                                        </div>
                                        <div className="p-2 rounded border" style={{ flexBasis: '32%' }}>
                                            <img src="/Images/icon7.png" className="img-fluid mb-1" style={{ width: 40 }} />
                                            <p className="mb-0">Top 1% Sillicon Valley Mentorship</p>
                                        </div>
                                    </div>
                                </div>


                                <div className="price-value">
                                    <span className="price-title">
                                        Old Price <b><del><i class="fas fa-rupee-sign small text-dark"></i>&nbsp;107999</del></b>
                                    </span>
                                    <span className="price-amount">
                                        {" "}
                                        <i class="fas fa-rupee-sign small text-dark"></i>&nbsp;99999
                     <span className="duration badge badge-pill">save 4%</span>
                                    </span>
                                </div>
                                <div className="pricingTable-signup">
                                    <a href="#">Book Now</a>
                                </div>
                            </div>
                        </Col> */}

                    </Row>
                </Container>

            </div>
        );
    }
}

export default courseplan;