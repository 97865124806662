import React, { Component } from 'react';
import { Container, Row, Col } from 'react-bootstrap';

class Managementteam extends Component {
    render() {
        return (
            <div className="py-5">
                <Container>
                    <h1 className="pb-2" style={{ fontSize: 30, fontWeight: '600' }}>Management Team</h1>
                    <Row>
                        <Col md={3}>
                            <div className="border p-2 rounded manage_team mb-2">
                                <img src="/Images/cmd.jpg" className="img-fluid mb-2" />
                                <h4>Mrs.Bhakti Ojha Kherani </h4>
                                <p>Managing Director – IANT Educom</p>
                            </div>
                        </Col>

                        <Col md={3}>
                            <div className="border p-2 rounded manage_team mb-2">
                                <img src="/Images/unnamed.jpeg" className="img-fluid mb-2" />
                                <h4>Mr.K.K.Bhojani </h4>
                                <p>Executive Director - IANTKids</p>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </div>
        );
    }
}

export default Managementteam;