import React, { Component, Suspense } from "react";
import { Redirect, Route, Switch } from "react-router-dom";
import routes from '../routes';

const loading = (
  <div className="pt-3 text-center">
    <div className="sk-spinner sk-spinner-pulse">Loading....</div>
  </div>
);

class Content extends Component {
  render() {
    return (
      <div>
        <Suspense fallback={loading}>
          <Switch>
            {routes.map((route, idx) => {
              return (
                route.component && (
                  <Route
                    key={idx}
                    path={route.path}
                    exact={route.exact}
                    name={route.name}
                    render={(props) => (
                      <div className="">
                        <route.component {...props} />
                      </div>
                    )}
                  />
                )
              );
            })}
            <Redirect from="/" to="/Home" />
          </Switch>
        </Suspense>
      </div>
    );
  }
}

export default Content;
