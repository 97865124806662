import React, { Component } from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import BackToTop from "react-back-to-top-button";
import axios from 'axios';
import UserService from '../../services/UserService';
import Cookies from 'universal-cookie'

const cookies = new Cookies();

class Courseplanreg extends Component {

  constructor(props) {
    super(props)
    this.state = {
      kgmid: localStorage.getItem('kgmid'),
      kdpid: localStorage.getItem('kdipd'),
      old: localStorage.getItem('old'),
      new: localStorage.getItem('newp'),
      INQGuId: '',
      KGMGuId: '',
      INQParentName: '',
      INQParentEmail: '',
      INQParentMobile: '',
      INQPassword: '',
      INQKidsName: '',
      INQIsLaptop: false,
      INQAlternetNumber: '',
      INQAlternetEmail: '',
      INQCreateDate: '',
      INQStatus: '',
      INQIsDemo: '',
      Profiles: [],
      loading1: false,
      Title: localStorage.getItem('title'),
      // url: window.location.href.replace("https://staging.iantkids.com/registers/", "")
    }

    // console.log('jay', this.state.kgmid, this.state.kdpid)

    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.CheckEmail = this.CheckEmail.bind(this);
    this.CheckMobile = this.CheckMobile.bind(this);
  }


  componentDidMount() {
    const t = cookies.get('tkn');


  }



  CheckMobile() {

    if (typeof this.state.INQParentMobile !== "undefined") {

      var pattern = new RegExp(/^[0-9\b]+$/);

      if (!pattern.test(this.state.INQParentMobile)) {

        this.setState({ mobileerroe: "Please enter only number" })

      } else if (this.state.INQParentMobile.length != 10) {

        this.setState({ mobileerroe: "Please enter only number" })

      }
      else {
        this.setState({ mobileerroe: "" })
      }

    }

    axios.get(UserService.API_URL1 + 'Inquiry/CheckMobile/' + this.state.INQParentMobile).then(resp => {

      console.log(resp.data)
      const Email = resp.data;

      if (Email === 1) {
        this.setState({ mobileerroe: "This Mobile Is Already Exit" })
      }
    });
  }


  CheckEmail() {

    if (this.state.INQParentEmail === '') {
      this.setState({ emailerrer: "Places Enter Email" })
    }
    else {
      this.setState({ emailerrer: "" })
    }

    // alert(this.state.INQParentEmail)
    axios.get(UserService.API_URL1 + 'Inquiry/CheckEmail/' + this.state.INQParentEmail).then(resp => {

      // console.log(resp.data)
      const Email = resp.data;

      if (Email === 1) {
        this.setState({ emailerrer: "This Email Is Already Used" })
      }
    });
  }


  handleChange(event) {
    const { name, value } = event.target;
    this.setState({
      [name]: value
    });

  }


  handleSubmit(event) {

    event.preventDefault();
    console.log(event);

    // if (cookies.get('tkn') !== undefined) {

    //   this.setState({ loading1: true });
    //   localStorage.setItem('Email', this.state.INQParentEmail)
    //   localStorage.setItem('Name', this.state.INQKidsName)

    //   this.setState({ loading1: false });

    //   window.location.href = '/success'
    // }
    // else {
    this.setState({ loading1: true });

    axios.post(UserService.API_URL1 + 'Inquiry/Book', {
      INQParentName: this.state.INQParentName,
      INQParentEmail: this.state.INQParentEmail,
      INQParentMobile: this.state.INQParentMobile,
      INQKidsName: this.state.INQKidsName,
      KGMGuId: this.state.kdpid,
      INQIsLaptop: this.state.INQIsLaptop,
      INQGuId: this.state.INQGuId
    }, {
      headers: {
        //  Authorization: 'Bearer ' + localStorage.getItem('tokens'),
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': '*',
      },
    })
      .then((response) => {
        //  console.log(response.data);
        if (response.data !== null) {

          // alert('Successfully Registered ')

          // localStorage.setItem('ID', response.data.inqGuId)
          this.setState({ loading1: false });

          localStorage.setItem('Email', response.data.inqParentEmail)
          localStorage.setItem('Name', response.data.inqKidsName)

          const cookies = new Cookies();

          //   cookies.set('ID', response.data.inqGuId, { path: 'http://localhost:3001/schedule' });
          //   cookies.set('tkn', response.data.inqWebsiteName, { path: 'http://localhost:3001/schedule' });

          // cookies.set('ID', response.data.inqGuId, { path: 'http://localhost:3000/' });
          // cookies.set('tkn', response.data.inqWebsiteName, { path: 'http://localhost:3000/' });

          cookies.set('ID', response.data.inqGuId, { path: '/', domain: '.iantkids.com' });
          cookies.set('tkn', response.data.inqWebsiteName, { path: '/', domain: '.iantkids.com' });

          this.setState({ loading1: false })
          ///  window.location.href = 'https://coder.iantkids.com'
          // window.location.href = 'http://localhost:3001/schedule'
          window.location.href = '/success'
        }
        else {
          alert('Error ')
          this.setState({ loading1: false })
        }

      })
      .catch(function (error) {
        console.log(error);
        this.setState({ loading1: false })
      });

    //  }


  }

  render() {

    const { loading1, } = this.state;
    const sub = parseInt(this.state.old) - parseInt(this.state.new);

    return (

      <div className="account_page py-md-3 py-0 mob-height" style={{ background: '#fff' }}>

        <div className="form-bg">
          <Container className="">

            <div className="course-register d-md-flex mx-auto" style={{ maxWidth: '100%', width: 750, boxShadow: '0 .5rem 1rem rgba(0,0,0,.15)' }}>

              <div className="form-container shadow-none" style={{ flexBasis: '60%' }}>
                <div className="form-icon">
                  <div className="d-flex align-items-center justify-content-between mb-3">
                    <h5 className="" style={{ color: '#F58732', fontSize: 23, fontWeight: '700' }}>PRELAUNCH OFFER <br />
                      <span className="text-info" style={{ fontWeight: '500', fontSize: 17 }}>Registration</span>
                    </h5>
                    <Link to="/"><img
                      src="images/logo.jpg"
                      className="img-fluid logo mt-md-n3"
                    /></Link>
                  </div>
                  <form onSubmit={this.handleSubmit}>
                    <div className="form-row">
                      <Col md={12} className="form-group">
                        <input
                          type="email"
                          className="form-control"
                          placeholder="Enter Parent's Email ID"
                          name="INQParentEmail" value={this.state.INQParentEmail}
                          required onChange={this.handleChange}
                          onBlur={this.CheckEmail}
                        />
                        <span style={{ color: 'red' }}>{this.state.emailerrer}</span>
                      </Col>
                      <Col md={12} className="form-group">
                        <input
                          type="text"
                          className="form-control"
                          placeholder="Enter Parent's Name"
                          name="INQParentName" value={this.state.INQParentName}
                          required onChange={this.handleChange}

                        />
                      </Col>

                      <Col md={2} xs={3}>
                        <select name="INQGuId" className="form-control ap-none" required onChange={this.handleChange}>
                          <option value="+91">+91</option>
                        </select>
                      </Col>
                      <Col md={10} xs={9} className="form-group">
                        <input
                          type="number"
                          className="form-control"
                          placeholder="Parent's Mobile"
                          name="INQParentMobile" value={this.state.INQParentMobile}
                          required onChange={this.handleChange}
                          onBlur={this.CheckMobile}
                        />
                        <span style={{ color: 'red' }}>{this.state.mobileerroe}</span>
                      </Col>
                      <Col md={12} className="form-group">
                        <input
                          type="text"
                          className="form-control"
                          placeholder="Enter Your Child Name"
                          name="INQKidsName" value={this.state.INQKidsName}
                          required onChange={this.handleChange}
                        />
                      </Col>


                    </div>




                    {/* <h6>Payment Details</h6>
                    <div className="paymet_table rounded">
                      <table className="table mb-0 table-borderless">
                        <tr>
                          <td>Beginner Standard 48 Classes</td>
                          <td className="text-right amt" style={{ width: 120 }}>₹ {this.state.old}</td>
                        </tr>
                        <tr>
                          <td>Special discount applied Change</td>
                          <td className="text-right amt" style={{ width: 120 }}>- ₹ {sub}</td>
                        </tr>
                        <tr className="border border-top">
                          <th className="text-theme">Order Total</th>
                          <td className="text-right amt text-theme" style={{ width: 120 }}>₹ {this.state.new}</td>
                        </tr>
                      </table>
                    </div>

                    <div
                      className="btn btn-block  mt-3 text-white p-2"
                      style={{ backgroundColor: '#f58732' }}
                    >
                      Your Total <b>₹ {this.state.new}</b>
                    </div> */}


                    <button type="submit"
                      className="btn btn-block btn-theme mt-2 text-white p-3 font-weight-bold"
                      disabled={loading1}
                    >

                      {loading1 && (
                        <span>Loading...</span>
                        // <img src="/re2.gif" style={{ marginRight: "5px" }} />
                      )}

                      {!loading1 && <span>Book Now</span>}

                    </button>

                  </form>
                </div>
              </div>

              <div className="pr-md-0 d-md-block d-none" style={{ flexBasis: '40%' }}>
                <img src="/Images/kidslearn.png" className="img-fluid" ></img>
              </div>

            </div>
          </Container>
        </div>

        <BackToTop
          showOnScrollUp
          showAt={100}
          speed={2000}
          easing="easeInOutQuint"
          style={{ zIndex: 999, fontSize: 20 }}
        >
          {/* <button className="btn btn-theme text-white shadow">
            <span><i class="fas fa-chevron-up"></i></span>
          </button> */}
          <img src="/images/rocket.png" width="60" />
        </BackToTop>
        <Container>
          <div className="py-3">
            <img src={UserService.API_URL3 + "File/Plan/" + this.state.Title + ".png"} className="mx-auto d-block img-fluid" style={{}}></img>
          </div>
        </Container>
      </div>
    );
  }
}

export default Courseplanreg;