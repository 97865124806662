import React, { Component } from 'react';
import { Container, Row, Col } from 'react-bootstrap';

class Affiliations extends Component {
    render() {
        return (
            <div className="py-5">

                <Container>

                    <h1 className="pb-2" style={{ fontSize: 30, fontWeight: '600' }}>Affiliations</h1>
                    <Row>
                        <Col md={6}>
                            <div className="border p-2 rounded  mb-2">
                                <img src="/Images/star.jpeg" className="img-fluid mb-2" />

                            </div>
                        </Col>

                        <Col md={6}>
                            <div className="border p-2 rounded  mb-2">
                                <img src="/Images/comptia.jpeg" className="img-fluid mb-2" />

                            </div>
                        </Col>

                    </Row>
                </Container>

            </div>
        );
    }
}

export default Affiliations;