import React, { Component } from 'react';
import { Header, Footer, Content } from './Index';

class Layout extends Component {
    render() {
        return (
            <div>

                <Header />
                
                    <div className="content">

                        <Content />

                    </div>

                <Footer />
                
            </div>
        );
    }
}

export default Layout;