import React, { Component } from "react";
import { Link } from 'react-router-dom';
import { Modal, Form, Col, Row, Container } from 'react-bootstrap';

class Footer extends Component {

  getYear() {
    return new Date().getFullYear();
  }



  render() {
    return (
      <div>
        <footer className="footer-part bg-footer rounded-circle-right">
          <div className="inner text-style-light-2 text-left ptb-60 ptb-sm-40">

            <Container>


              <Row>
                <Col md={6} sm={6} lg={3} className="mb-3 mb-lg-0">
                  <div className="footer-logo">
                    <img src="../images/logo.svg" alt="logo" className="w-75" />
                  </div>
                  <p className="mt-20">IANTKids was created to align today’s kids with the need for 21st Century Skills-Critical Thinking, Problem Solving, Communication, Creativity and Decision Making.</p>
                </Col>
                <Col md={6} sm={6} lg={3}>
                  <h3 className="font-weight-bold">Courses</h3>
                  <ul className="list-unstyled">
                    <li><a href="/courses/beginner">Beginner</a></li>
                    <li><a href="/courses/intermediate">Intermediate</a></li>
                    <li><a href="/courses/sharp coder">Sharp Coder</a></li>
                    <li><a href="/courses/smart coder">Smart Coder</a></li>
                    <li><a href="/courses/super coder">Super Coder</a></li>
                  </ul>
                </Col>
                <Col md={6} sm={6} lg={3}>
                  <h3 className="font-weight-bold">Company</h3>
                  <ul className="list-unstyled">
                    <li><Link to="/about">About Us</Link></li>

                    <li><Link to="/managementteam">Management Team</Link></li>
                    <li><Link to="/affiliations">Affiliations</Link></li>
                  </ul>
                </Col>
                <Col md={6} sm={6} lg={3}>
                  <h3 className="font-weight-bold">Stay Connnected</h3>
                  <ul className="social-icon style-solid-rounded-icon icon-size-medium text-left mt-3">
                    <li className="facebook"><a href="#" className="link hover-text-color" aria-label="Facebook"><i className="fab fa-facebook" /></a></li>
                    <li className="twitter"><a href="#" className="link hover-text-color" aria-label="Twitter"><i className="fab fa-twitter" /></a></li>
                    <li className="instagram"><a href="#" className="link hover-text-color" aria-label="Instagram"><i className="fab fa-instagram" /></a></li>
                    <li className="dribbble"><a href="#" className="link hover-text-color" aria-label="Dribbble"><i className="fab fa-linkedin" /></a></li>
                  </ul>
                </Col>
              </Row>
            </Container>
          </div>
          <Container>
            <div className="basic-thine-line" />
            <Row>
              <Col md={12} className="footer-copyright text-center border-top">
                <div className="copyright text-dark">
                  <p className="text-center mb-0 py-3">© Copyright {this.getYear()}, IANTKids. </p>
                </div>
              </Col>
            </Row>
          </Container>
          <div className="wave-wrapper">
            <img className="wave wave-single img-fluid" src="images/wave-single.png" alt="wave shape" />
          </div>
        </footer>

      </div>
    );
  }
}

export default Footer;
