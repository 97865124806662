import React, { Component } from 'react';
import { Container, Row, Col } from 'react-bootstrap';

class About extends Component {
    render() {
        return (
            <div>

                <Container>
                    <Row>
                        <Col md={12}>
                            <div className="p-md-4 m-2 my-3">

                                <h1 className="pb-3" style={{ fontWeight: '600', fontSize: 30 }}>IANTKids </h1>
                                <p>IANTKids  was created to align today’s kids with the need for 21st Century Skills-Critical Thinking, Problem Solving, Communication, Creativity and Decision Making.</p>

                                <p>Computers and Technology have engulfed Children’s lives. It is important for tapping the potential in your Child at an elementary stage. Their mind is at a developing stage and the studies in Cognitive development of Children vindicate the fact that the developing mind is the most receptive, impactful and curious. It is important to capitalize on this aspect and let children expand their development process.</p>

                                <p>IANTKids  is a Learning Platform catering to Students from Class 1 to Class 12. The Platform is powered by IANT-The World’s Biggest IT Training and Global Certification Company with 21 Years of IT Training Experience.</p>

                                <p>Today’s kids are much more adaptive to Technology. IANTKids  is looking to empower today’s kids with 21St Century Skills combining LEARNING AND FUN and training them on Coding, Robotics, AI & Data Science. IANTKids  aims for a paradigm shift in the way the Kids will be skilled, empowered and made future ready.</p>

                                <h5 className="font-weight-bold text-theme">SKILL- EMPOWER - FUTURE READY</h5>

                                <p>Our endeavor at IANTKids  is to make children acquire skills, which will make them FUTURE JOB CREATORS. It is important to make Kids learn from the basic levels and make their roots stronger.</p>

                                <p>Coding is the first step towards making Programs combined with New Age Technologies-Robotics, AI and Data Science. The Course Offerings have been holistically designed for Class 1 to Class 12.</p>

                                <p>Anyone and Everyone can Code…Just keep your Device-Computer or Laptop READY and let your CURIOUSITY define your path.</p>

                                <h5 className="font-weight-bold text-theme">Happiness is LEARNING!</h5>
                            </div>
                        </Col>
                    </Row>
                </Container>

            </div>
        );
    }
}

export default About;
