import React, { Component } from 'react';
import UserService from '../../services/UserService';
import { Container, Row } from 'react-bootstrap';
class Profile extends Component {
    constructor(props) {
        super(props)
        this.state = {
            Profiles: '',
            pid: localStorage.getItem('ID')
        }

        //  console.log(this.state.pid);

        fetch(UserService.API_URL1 + 'Inquiry/getprofile/' + this.state.pid, {
            method: 'GET',
            headers: {
                //Authorization: 'Bearer ' + localStorage.getItem('tokens'),
                'Content-Type': 'application/json',
                'Access-Control-Allow-Origin': '*'

            }
        })
            .then((response) => response.json())
            .then((response) => {
                console.log(response);
                this.setState({ Profiles: response, loading: false });
            },
                err => {
                    console.log(err);
                    // this.props.history.push('/Login');
                });
    }

    render() {
        return (
            <section className="py-5">
                <Container>
                    <Row>
                        <table className="table table-bordered">
                            <thead>
                                <tr>

                                    <th>ParentEmail</th>
                                    <th>ParentName</th>
                                    <th>ParentMobile</th>
                                    <th>ChildName</th>
                                    <th>Status</th>

                                </tr>
                            </thead>
                            <tbody>



                                <tr>

                                    <td>{this.state.Profiles.inqParentEmail}</td>
                                    <td>{this.state.Profiles.inqParentName}</td>
                                    <td>{this.state.Profiles.inqParentMobile}</td>
                                    <td>{this.state.Profiles.inqKidsName}</td>
                                    <td>{this.state.Profiles.inqStatus}</td>


                                </tr>



                            </tbody>
                        </table>
                    </Row>
                </Container>
            </section>
        );
    }
}

export default Profile;