import React, { Component } from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import BackToTop from "react-back-to-top-button";

class Payment extends Component {
  constructor(props) {
    super(props)
    this.state = {
      kgmid: localStorage.getItem('kgmid'),
      kdpid: localStorage.getItem('kdipd'),
      old: localStorage.getItem('old'),
      new: localStorage.getItem('newp'),
      INQGuId: '',
      KGMGuId: '',
      INQParentName: '',
      INQParentEmail: localStorage.getItem('Email'),
      INQParentMobile: '',
      INQPassword: '',
      INQKidsName: localStorage.getItem('Name'),
      INQIsLaptop: false,
      INQAlternetNumber: '',
      INQAlternetEmail: '',
      INQCreateDate: '',
      INQStatus: '',
      INQIsDemo: '',
      CLS: localStorage.getItem('cla')
    }

    console.log('jay', this.state.kgmid, this.state.kdpid)


  }

  render() {
    const sub = parseInt(this.state.old) - parseInt(this.state.new);

    return (
      <div className="account_page py-md-5 py-0 h-100" style={{ background: '#f5f5f5' }}>

        <div className="form-bg">
          <Container className="">

            <div className="course-register d-md-flex mx-auto shadow" style={{ maxWidth: '100%', width: 1000 }}>

              <div className="form-container shadow-none" style={{ flexBasis: '58%' }}>

                <div className="form-icon d-block">

                  <div className="d-md-flex align-items-center justify-content-between mb-3">
                    <h6 className="">Lifetime Education Nominee: Payment Request</h6>
                    <Link to="/"><img
                      src="images/logo.jpg"
                      className="img-fluid logo mt-md-n3"
                    /></Link>
                  </div>


                  <div className="paymet_table rounded bg-light mb-3 p-2 border" style={{}}>
                    <table className="table table-borderless border-0 mb-0">
                      <tr>
                        <td style={{ width: 140 }}>Child Name</td>
                        <td className="font-weight-normal">{this.state.INQKidsName}</td>
                      </tr>
                      <tr>
                        <td style={{ width: 140 }}>Email Id</td>
                        <td className="font-weight-normal">{this.state.INQParentEmail}</td>
                      </tr>
                      <tr className="" style={{ width: 140 }}>
                        <td className="">Payment For</td>
                        <td className="font-weight-normal">Intermidiate standard {this.state.CLS} classes</td>
                      </tr>
                    </table>
                  </div>

                  <h6 className="text-dark">Payment Details</h6>
                  <div className="rounded bg-white mb-3 p-2 border">
                    <table className="table mb-0 table-borderless">
                      <tr>
                        <td>Beginner standard 48 classes</td>
                        <td className="text-right amt" style={{ width: 120, fontWeight: '600' }}>₹ {this.state.old}</td>
                      </tr>
                      <tr>
                        <td>Special discount applied Change</td>
                        <td className="text-right amt" style={{ width: 120, fontWeight: '600' }}>- ₹ {sub}</td>
                      </tr>
                      <tr className="border-top">
                        <th className="text-theme">Order Total</th>
                        <td className="text-right amt text-theme" style={{ width: 120, fontWeight: '700' }}>₹ {this.state.new}</td>
                      </tr>
                    </table>
                  </div>

                  <div className="rounded bg-white mb-3 p-3 border">
                    <input type="radio" checked />&nbsp;Debit card / Credit card / Net banking
                          </div>

                  <div
                    className="btn btn-block  mt-2 text-white p-2"
                    style={{ backgroundColor: '#f58732' }}
                  >
                    Your Total <b>₹ {this.state.new}</b>
                  </div>

                  <Link to="/"
                    className="btn btn-block btn-theme mt-2 text-white p-3 font-weight-bold"
                  >
                    Pay Securely
                          </Link>


                </div>
              </div>


              <div className="pr-md-0 d-md-block d-none" style={{ flexBasis: '42%' }}>
                <img src="/Images/kidslearn.png" className="img-fluid h-100" ></img>
              </div>




            </div>
          </Container>
        </div>

        <BackToTop
          showOnScrollUp
          showAt={100}
          speed={2000}
          easing="easeInOutQuint"
          style={{ zIndex: 999, fontSize: 20 }}
        >
          {/* <button className="btn btn-theme text-white shadow">
                <span><i class="fas fa-chevron-up"></i></span>
              </button> */}
          <img src="/images/rocket.png" width="60" />
        </BackToTop>

      </div>
    );
  }
}

export default Payment;