import React, { Component } from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import BackToTop from "react-back-to-top-button";
import Cookies from 'universal-cookie'
const cookies = new Cookies();

class Success extends Component {
    constructor(props) {
        super(props)
        this.state = {
            kgmid: localStorage.getItem('kgmid'),
            kdpid: localStorage.getItem('kdipd'),
            old: localStorage.getItem('old'),
            new: localStorage.getItem('newp'),
            INQGuId: '',
            KGMGuId: '',
            INQParentName: '',
            INQParentEmail: localStorage.getItem('Email'),
            INQParentMobile: '',
            INQPassword: '',
            INQKidsName: localStorage.getItem('Name'),
            INQIsLaptop: false,
            INQAlternetNumber: '',
            INQAlternetEmail: '',
            INQCreateDate: '',
            INQStatus: '',
            INQIsDemo: '',
            CLS: localStorage.getItem('cla'),
            Title: localStorage.getItem('title')
        }

        //  console.log('jay', this.state.kgmid, this.state.kdpid)
        // console.log(cookies.get('tkn'))
        if (cookies.get('tkn') === undefined) {
            window.location.href = '/'
        }

    }


    render() {
        return (
            <div className="account_page py-md-5 py-0 mob-height" style={{ background: '#f5f5f5' }
            }>

                <div className="form-bg">
                    <Container className="">

                        <div className="course-register d-md-flex mx-auto" style={{ maxWidth: '100%', width: 750, boxShadow: '0 .5rem 1rem rgba(0,0,0,.15)' }}>

                            <div className="form-container shadow-none" style={{ flexBasis: '60%' }}>

                                <Link to="/">
                                    <img
                                        src="images/logo.jpg"
                                        className="img-fluid logo d-block mx-auto mt-2"
                                        style={{ width: 140 }}
                                    />
                                </Link>

                                <div className="form-icon">

                                    <div className="pb-2">
                                        <h6 style={{ fontWeight: '600' }}>Booking for <span className="badge badge-pill badge-info" style={{ paddingBottom: 5 }}>{this.state.CLS}</span> of <span className="badge badge-pill bg-theme2 text-white" style={{ paddingBottom: 5 }}>{this.state.Title.toLocaleLowerCase()}</span> successfully completed</h6>
                                        {/* <p style={{ fontSize: 14, fontWeight: '600' }}> </p> */}

                                    </div>

                                    <h6 style={{ fontWeight: '600', fontSize: 14 }}>Booking Information</h6>
                                    <div>

                                        <div className="paymet_table rounded bg-light mb-4 p-2 border" style={{}}>
                                            <table className="table table-borderless border-0 mb-0">
                                                <tr>
                                                    <td style={{ width: 140 }}>Child Name</td>
                                                    <td className="font-weight-normal">{this.state.INQKidsName}</td>
                                                </tr>
                                                <tr>
                                                    <td style={{ width: 140 }}>Email Id</td>
                                                    <td className="font-weight-normal">{this.state.INQParentEmail}</td>
                                                </tr>
                                                <tr className="" style={{ width: 140 }}>
                                                    <td className="">Booking for Session </td>
                                                    <td className="font-weight-normal"><span className="badge badge-pill badge-info" style={{ paddingBottom: 5 }}>{this.state.Title.toLocaleLowerCase()}</span>  <span className="badge badge-pill bg-theme2 text-white" style={{ paddingBottom: 5 }}>{this.state.CLS}</span> </td>
                                                </tr>
                                            </table>
                                        </div>
                                    </div>

                                    {/* <a href='http://localhost:3001/create-website' className="btn btn-theme btn-block text-white mb-1 animated bounce" style={{ animationIterationCount: 'infinite', animationDuration: '2s' }}>Create {this.state.INQKidsName}'s Website</a> */}


                                    <div className="p-md-3 p-2 bg-theme2 text-white text-center rounded shadow">
                                        <h6 className="mb-0">Our Representative will contact you soon!</h6>
                                    </div>
                                </div>
                            </div>


                            <div className="pr-md-0 d-md-block d-none" style={{ flexBasis: '40%' }}>
                                <img src="/Images/kidslearn.png" className="img-fluid " ></img>
                            </div>

                        </div>
                    </Container>
                </div>

                <BackToTop
                    showOnScrollUp
                    showAt={100}
                    speed={2000}
                    easing="easeInOutQuint"
                    style={{ zIndex: 999, fontSize: 20 }}
                >
                    {/* <button className="btn btn-theme text-white shadow">
            <span><i class="fas fa-chevron-up"></i></span>
          </button> */}
                    <img src="/images/rocket.png" width="60" />
                </BackToTop>

            </div >
        );
    }
}

export default Success;