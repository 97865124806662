import React from 'react';

import Home from './Views/Home/Home';
import Profile from './Views/Profile/Profile';
import About from './Views/About/About';
import Cources from './Views/Courses/courses';
import Trial from './Views/Trail/trail';
import Managementteam from './Views/Managementteam/Managementteam';
import Affiliations from './Views/Affiliations/Affiliations';

//import Registration from './Views/Registration/Register';


const routes = [
    { path: '/', name: '', component: Home, exact: true },
    { path: '/Profile', name: '', component: Profile, },
    { path: '/about', name: '', component: About, },
    { path: '/courses/:id', name: '', component: Cources, },
    { path: '/managementteam', name: 'Managementteam', component: Managementteam },
    { path: '/affiliations', name: 'Affiliations', component: Affiliations },



    //{ path: '/register', name: '', component: Registration},
]

export default routes;