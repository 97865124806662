import React, { Component } from 'react';
import { Container } from 'react-bootstrap';
import UserService from '../../services/UserService';
class courses extends Component {

    constructor(props) {
        super(props)
        this.state = {
            Profiles: [],
            url: window.location.href.replace("https://staging1.iantkids.com/courses/", "")
        }

        // fetch(UserService.API_URL1 + 'Inquiry/getprofile/' + this.state.pid, {
        //     method: 'GET',
        //     headers: {
        //         //Authorization: 'Bearer ' + localStorage.getItem('tokens'),
        //         'Content-Type': 'application/json',
        //         'Access-Control-Allow-Origin': '*'

        //     }
        // })
        //     .then((response) => response.json())
        //     .then((response) => {
        //         console.log(response);
        //         this.setState({ Profiles: response, loading: false });
        //     },
        //         err => {
        //             console.log(err);
        //             // this.props.history.push('/Login');
        //         });


    }



    render() {

        return (

            <Container>
                <div className="py-2">
                    <img src={UserService.API_URL3 + "File/Plan/" + this.state.url + ".png"} className="mx-auto d-block img-fluid" style={{}}></img>
                </div>
            </Container>
        );
    }
}

export default courses;